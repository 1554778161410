<template>
    <div>
        <PageTitle title="Email" :breadcrumb="[{ name: 'Email' }]">
        </PageTitle>

        <div class="page-body">
            <div class="container-xl">
                <div class="col-12">
                    <div class="card">
                        <div class="list-group list-group-flush list-group-hoverable">
                            <div class="list-group-item" v-for="(email, index) in emails" :key="index">
                            <div class="row align-items-center">
                                <div class="col-auto"><span class="badge bg-green"></span></div>
                                <div class="col-auto">
                                <a href="#">
                                    <span class="avatar"><i class="ti ti-mood-boy"></i></span>
                                </a>
                                </div>
                                <div class="col text-truncate">
                                    <router-link class="text-reset d-block" :to="{name: 'EmailDetails', params: { id: email.id } }"> {{email.name}} ({{ email.email }}) </router-link>
                                    <div class="d-block text-muted text-truncate mt-n1">{{email.subject}} - {{ email.created_at }}</div>
                                </div>
                                <div class="col-auto">
                                <a @click="deleteBlog(email.id)" class="list-group-item-actions"><!-- Download SVG icon from http://tabler-icons.io/i/star -->
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash-filled" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M20 6a1 1 0 0 1 .117 1.993l-.117 .007h-.081l-.919 11a3 3 0 0 1 -2.824 2.995l-.176 .005h-8c-1.598 0 -2.904 -1.249 -2.992 -2.75l-.005 -.167l-.923 -11.083h-.08a1 1 0 0 1 -.117 -1.993l.117 -.007h16z" stroke-width="0" fill="currentColor" />
                                    <path d="M14 2a2 2 0 0 1 2 2a1 1 0 0 1 -1.993 .117l-.007 -.117h-4l-.007 .117a1 1 0 0 1 -1.993 -.117a2 2 0 0 1 1.85 -1.995l.15 -.005h4z" stroke-width="0" fill="currentColor" />
                                    </svg>
                                </a>
                                </div>
                            </div>
                            </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageTitle from '../../components/PageTitle';
import { authCheck } from '@/helpers/auth/auth-check';
import { ref, onMounted, inject } from 'vue';
import { useRouter, useRoute } from "vue-router";
import axios from 'axios';
import { store } from '../../store.js';

export default {
    name: 'CreateBlog',
    components: {
        PageTitle
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        let emails = ref();
        const swal = inject('$swal');

        async function getBlog() {
            store.loading = true;
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/api/email')
            .then(function (res) {
                store.loading = false;
                emails.value = res.data.data;
            })
            .catch(function (error) {
                if (error.response.status == 401) {
                    router.push({ name: 'Logout' });
                }
            });
        }
        onMounted(() => {
            authCheck();
            getBlog();
        });
        return { emails, route, router, swal };
    },
    methods: {
        deleteBlog(id){
            this.swal({
                title: "Delete this Email?",
                text: "Are you sure? You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#b81307",
                confirmButtonText: "Yes, Delete it!"
            }).then((result) => { // <--
                if (result.value) { // <-- if confirmed
                    store.loading = true;

                    axios
                    .delete(process.env.VUE_APP_ENDPOINT+'/api/email/'+id)
                    .then(function () {
                        store.loading = false;
                        window.location.reload();
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            this.router.push({ name: 'Logout' });
                        }
                    });
                }
            });
            
        }
    },
}
</script>