<template>
    <div>
        <PageTitle title="Information Details" :breadcrumb="[{ name: 'Personal Info', link: '/admin/info' }, { name: 'Details' }]">
        </PageTitle>
        <div class="page-body">
            <div class="container-xl">
                <div class="card card-lg">
                    <div class="card-body">
                        <div class="space-y-4">
                            <div class="row g-4">
                                <div class="col-12 markdown">
                                    <h3>Personal Details</h3>
                                    <blockquote class="blockquote">
                                    <p v-html="info.about_content"></p>
                                    <footer class="blockquote-footer">{{info.name}}</footer>
                                    </blockquote>
                                </div>
                                <div class="col-md-4 markdown">
                                    <h3>Social Media</h3>
                                    <ul class="list-unstyled">
                                        <li><i class="ti ti-brand-github h2"></i> : {{info.github}}</li>
                                        <li><i class="ti ti-brand-linkedin h2"></i> : {{info.linkedin}}</li>
                                        <li><i class="ti ti-brand-facebook h2"></i> : {{info.facebook}}</li>
                                        <li><i class="ti ti-brand-twitter h2"></i> : {{info.twitter}}</li>
                                    </ul>
                                </div>
                                <div class="col-md-4 markdown">
                                    <h3>Others</h3>
                                    <ul class="list-unstyled">
                                        <li>Age : {{info.age}}</li>
                                        <li>Communication : {{info.language}}</li>
                                        <li>Nationality : {{info.nationality}}</li>
                                        <li>Freelance Status : {{info.freelance_status}}</li>
                                        <li>CV : <a :href="info.cvfile" target="_blank">{{info.cvfile}}</a></li>
                                    </ul>
                                </div>
                                <div class="col-md-4 markdown">
                                    <h3>Addresses</h3>
                                    <address><strong>{{info.address}} <br>
                                    </strong>E: {{info.email}}<br>
                                    <abbr title="Phone">P: </abbr>{{info.phone}}
                                    </address>
                                </div>

                                <div class="mt-5 col-md-4">
                                    <h3>About Image</h3>
                                    <img v-if="info.about_image !== ''" :src="info.about_image" class="rounded" height="200">
                                </div>
                                <div class="mt-5 col-md-4">
                                    <h3>About Image Large</h3>
                                    <img v-if="info.about_image_lg !== ''" :src="info.about_image_lg" class="rounded" width="400">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageTitle from '../../components/PageTitle';
import { authCheck } from '@/helpers/auth/auth-check';
import { ref, onMounted } from 'vue';
import { useRouter,useRoute } from "vue-router";
import axios from 'axios';
import { store } from '../../store.js';

export default {
    name: 'InfoDetails',
    components: {
        PageTitle
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        let info = ref([]);

        async function getInfoDetails() {
            store.loading = true;
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/api/info/'+route.params.id)
            .then(function (res) {
                store.loading = false;
                info.value = res.data.information;
            })
            .catch(function (error) {
                if (error.response.status == 401) {
                    router.push({ name: 'Logout' });
                }
            });
        }
        onMounted(() => {
            authCheck();
            getInfoDetails();
        });
        return { info };
    }
}
</script>