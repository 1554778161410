    <template>
    <div>
       <PageTitle title="Edit Info" :breadcrumb="[{ name: 'Personal Info', link: '/admin/info' }, { name: 'Edit' }]">

        </PageTitle>
        <div class="page-body">
            <div class="container-xl">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">Name</label>
                                        <input type="text" class="form-control" v-model="name" placeholder="Name">
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">Nationality</label>
                                        <input type="text" class="form-control" v-model="nationality" placeholder="Nationality">
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">Language</label>
                                        <input type="text" class="form-control" v-model="language" placeholder="Language">
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="mb-3">
                                        <label class="form-label">Address</label>
                                        <input type="text" class="form-control" v-model="address" placeholder="Address">
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">About</label>
                                        <!-- <button @click="importBtn" class="btn btn-outline-primary" ><i class="ti ti-cloud-upload"></i> <span>Upload Photo</span></button> -->
                                        <input class="form-control inputFile" id="inputFile" name="inputFile" type="file" accept=".jpg,.jepg,.png" v-on:change="aboutImgUpload" :class="{ 'is-invalid': error.aboutImg}">
                                        <img :src="aboutImg" class="img-thumbnail" width="100">
                                        <div class="invalid-feedback">{{ error.aboutImg }}</div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">About (Background)</label>
                                        <input class="form-control inputFile" id="inputFile" name="inputFile" type="file" accept=".jpg,.jepg,.png" v-on:change="aboutLgImgUpload" :class="{ 'is-invalid': error.aboutLgImg}">
                                        <img :src="aboutLgImg" class="img-thumbnail" width="100">
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">Profile Image</label>
                                        <input class="form-control inputFile" id="inputFile" name="inputFile" type="file" accept=".jpg,.jepg,.png" v-on:change="profileUpload">
                                        <img :src="profile" class="img-thumbnail" width="100">
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">Resume</label>
                                        <input class="form-control inputFile" id="inputFile" name="inputFile" type="file" v-on:change="resumeUpload">
                                        <div class="text-info mt-3">{{resume}}</div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">Age</label>
                                        <input type="text" class="form-control" v-model="age" placeholder="Age">
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">Status</label>
                                        <input type="text" class="form-control" v-model="status" placeholder="Status">
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">Email</label>
                                        <input type="email" class="form-control" v-model="email" placeholder="Email">
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">Mobile</label>
                                        <input type="text" class="form-control" v-model="mobile" placeholder="Mobile">
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">Linkedin</label>
                                        <input type="text" class="form-control" v-model="linkedin" placeholder="Linkedin">
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">Github</label>
                                        <input type="text" class="form-control" v-model="github" placeholder="Github">
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">Facebook</label>
                                        <input type="text" class="form-control" v-model="facebook" placeholder="Facebook">
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">Dribbble</label>
                                        <input type="text" class="form-control" v-model="dribbble" :class="{ 'is-invalid': error.dribbble}" placeholder="dribbble">
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">Pinterest</label>
                                        <input type="text" class="form-control" v-model="pinterest" placeholder="Pinterest">
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">Twitter</label>
                                        <input type="text" class="form-control" v-model="twitter" placeholder="Twitter">
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">Behance</label>
                                        <input type="text" class="form-control" v-model="behance" placeholder="Behance">
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="mb-3">
                                        <label class="form-label">Content</label>
                                        <editor
                                           api-key="vurtoro60e1p8issqadzfw43v19naju6d98ggqr1tnggr8h9"
                                            :init="{
                                                menubar: false,
                                                plugins: 'lists link image emoticons',
                                                toolbar: 'styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link image emoticons'
                                            }"
                                            v-model="content"
                                            :class="{ 'is-invalid': error.content}"
                                        />
                                        <div class="invalid-feedback">{{ error.content }}</div>
                                    </div>
                                </div>

                                <div class="mt-4">
                                    <div class="d-flex">
                                        <button type="submit" class="btn btn-primary ms-auto" @click="update">
                                            <span>Update</span> 
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageTitle from '../../components/PageTitle';
import { authCheck } from '@/helpers/auth/auth-check';
import Editor from '@tinymce/tinymce-vue'
import { ref, onMounted, inject } from 'vue';
import { useRouter, useRoute } from "vue-router";
import { store } from '../../store.js';
import axios from 'axios';

export default {
    name: 'CreateBlog',
    components: {
        PageTitle,
        'editor': Editor
    },
    setup() {
        const router = useRouter();
        const name = ref('');
        const error = ref([]);
        const nationality = ref('');
        const aboutImg = ref('');
        const aboutLgImg = ref('');
        const profile = ref('');
        const resume = ref('');
        const age = ref('');
        const status = ref('');
        const email = ref('');
        const linkedin = ref('');
        const github = ref('');
        const facebook = ref('');
        const dribbble = ref('');
        const pinterest = ref('');
        const twitter = ref('');
        const behance = ref('');
        const content = ref('');
        const language = ref('');
        const address = ref('');
        const mobile = ref('');
        const swal = inject('$swal');
        const route = useRoute();

        async function getInfoDetails() {
            store.loading = true;
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/api/info/'+route.params.id+'/edit')
            .then(function (res) {
                store.loading = false;
                name.value = res.data.information.name;
                nationality.value = res.data.information.nationality;
                aboutImg.value = res.data.information.about_image;
                aboutLgImg.value = res.data.information.about_image_lg;
                profile.value = res.data.information.brand_image;
                resume.value = res.data.information.cvfile;
                age.value = res.data.information.age;
                status.value = res.data.information.freelance_status;
                email.value = res.data.information.email;
                linkedin.value = res.data.information.linkedin;
                github.value = res.data.information.github;
                facebook.value = res.data.information.facebook;
                dribbble.value = res.data.information.dribbble;
                pinterest.value = res.data.information.pinterest;
                twitter.value = res.data.information.twitter;
                behance.value = res.data.information.behance;
                content.value = res.data.information.about_content;
                language.value = res.data.information.language;
                address.value = res.data.information.address;
                mobile.value = res.data.information.phone;
            })
            .catch(function (error) {
                if (error.response.status == 401) {
                    router.push({ name: 'Logout' });
                }
            });
        }

        onMounted(() => {
            authCheck();
            getInfoDetails();
        });
        return { store, swal, router, route, error, name, nationality, aboutImg, aboutLgImg,
                profile, resume, age, status, email, mobile, linkedin, github, facebook, 
                address, language, dribbble, pinterest, twitter, behance, content }
    },
    methods: {
        aboutImgUpload(e) {
            var files = e.target.files || e.dataTransfer.files;
            this.aboutImg = files[0];
        },
        aboutLgImgUpload(e) {
            var files = e.target.files || e.dataTransfer.files;
            this.aboutLgImg = files[0];
        },
        profileUpload(e) {
            var files = e.target.files || e.dataTransfer.files;
            this.profile = files[0];
        },
        resumeUpload(e) {
            var files = e.target.files || e.dataTransfer.files;
            this.resume = files[0];
        },
        async update() {
            store.loading = true;
            let model = this;
            await axios
            .post(process.env.VUE_APP_ENDPOINT+'/api/info/'+model.route.params.id,{
                name: this.name,
                about_content: this.content,
                age: this.age,
                phone: this.mobile,
                nationality: this.nationality,
                language: this.language,
                email: this.email,
                address: this.address,
                freelance_status: this.status,
                facebook: this.facebook,
                twitter: this.twitter,
                pinterest: this.pinterest,
                behance: this.behance,
                linkedin: this.linkedin,
                dribbble: this.dribbble,
                github: this.github,
                brand_image: this.profile,
                about_image: this.aboutImg,
                about_image_lg: this.aboutLgImg,
                cvfile: this.resume,
                _method: 'put'
            },{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(function (res) {
                store.loading = false;
                model.swal({
                    icon: 'success',
                    text: res.data.message,
                    showCloseButton: true,
                    showConfirmButton: false
                }).then(function(){
                    window.location.reload();
                });
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    model.router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 422) {
                    model.swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    model.swal({
                        icon: 'error',
                        text: model.app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        }
    }
}
</script>

<style scoped>
    .hide{
        display: none;
    }
</style>