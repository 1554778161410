<template>
    <div>
        <PageTitle title="Blog Details" :breadcrumb="[{ name: 'Blog', link: '/admin/blog' }, { name: 'Details' }]">
        </PageTitle>
        <div class="page-body">
            <div class="container-xl">
                <div class="card card-lg">
                    <div class="card-body">
                        <div class="space-y-4">
                            <div>
                                <img v-if="blog.featured_image !== ''" :src="blog.featured_image" class="rounded" height="200">
                                <div class="header mt-5 mb-3">
                                    <h1>{{ blog.title }}</h1>
                                    <h4 class="text-muted">{{ blog.created_at }}</h4>
                                </div>
                                
                                <div v-html="blog.details"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageTitle from '../components/PageTitle';
import { authCheck } from '@/helpers/auth/auth-check';
import { ref, onMounted } from 'vue';
import { useRouter,useRoute } from "vue-router";
import axios from 'axios';
import { store } from '../store.js';

export default {
    name: 'CreateBlog',
    components: {
        PageTitle
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        let blog = ref([]);

        async function getBlogDetails() {
            store.loading = true;
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/api/blog/'+route.params.id)
            .then(function (res) {
                store.loading = false;
                blog.value = res.data.blog;
            })
            .catch(function (error) {
                if (error.response.status == 401) {
                    router.push({ name: 'Logout' });
                }
            });
        }
        onMounted(() => {
            authCheck();
            getBlogDetails();
        });
        return { blog };
    }
}
</script>