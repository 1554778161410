<template>
    <div>
       <PageTitle title="Create Info" :breadcrumb="[{ name: 'Personal Info', link: '/admin/info' }, { name: 'Create' }]">

        </PageTitle>
        <div class="page-body">
            <div class="container-xl">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">Name</label>
                                        <input type="text" class="form-control" v-model="name" :class="{ 'is-invalid': error.name}" placeholder="Name">
                                        <div class="invalid-feedback">{{ error.name }}</div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">Nationality</label>
                                        <input type="text" class="form-control" v-model="nationality" :class="{ 'is-invalid': error.nationality}" placeholder="Nationality">
                                        <div class="invalid-feedback">{{ error.nationality }}</div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">Language</label>
                                        <input type="text" class="form-control" v-model="language" :class="{ 'is-invalid': error.language}" placeholder="Language">
                                        <div class="invalid-feedback">{{ error.language }}</div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="mb-3">
                                        <label class="form-label">Address</label>
                                        <input type="text" class="form-control" v-model="address" :class="{ 'is-invalid': error.address}" placeholder="Address">
                                        <div class="invalid-feedback">{{ error.address }}</div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">About</label>
                                        <!-- <button @click="importBtn" class="btn btn-outline-primary" ><i class="ti ti-cloud-upload"></i> <span>Upload Photo</span></button> -->
                                        <input class="form-control inputFile" id="inputFile" name="inputFile" type="file" accept=".jpg,.jepg,.png" v-on:change="aboutImgUpload" :class="{ 'is-invalid': error.aboutImg}">
                                        <div class="invalid-feedback">{{ error.aboutImg }}</div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">About (Background)</label>
                                        <!-- <button @click="importBtn" class="btn btn-outline-primary" ><i class="ti ti-cloud-upload"></i> <span>Upload Photo</span></button> -->
                                        <input class="form-control inputFile" id="inputFile" name="inputFile" type="file" accept=".jpg,.jepg,.png" v-on:change="aboutLgImgUpload" :class="{ 'is-invalid': error.aboutLgImg}">
                                        <div class="invalid-feedback">{{ error.aboutLgImg }}</div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">Profile Image</label>
                                        <!-- <button @click="importBtn" class="btn btn-outline-primary" ><i class="ti ti-cloud-upload"></i> <span>Upload Photo</span></button> -->
                                        <input class="form-control inputFile" id="inputFile" name="inputFile" type="file" accept=".jpg,.jepg,.png" v-on:change="profileUpload" :class="{ 'is-invalid': error.profile}">
                                        <div class="invalid-feedback">{{ error.profile }}</div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">Resume</label>
                                        <!-- <button @click="importBtn" class="btn btn-outline-primary" ><i class="ti ti-cloud-upload"></i> <span>Upload Photo</span></button> -->
                                        <input class="form-control inputFile" id="inputFile" name="inputFile" type="file" v-on:change="resumeUpload" :class="{ 'is-invalid': error.resume}">
                                        <div class="invalid-feedback">{{ error.resume }}</div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">Age</label>
                                        <input type="text" class="form-control" v-model="age" :class="{ 'is-invalid': error.age}" placeholder="Age">
                                        <div class="invalid-feedback">{{ error.age }}</div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">Status</label>
                                        <input type="text" class="form-control" v-model="status" :class="{ 'is-invalid': error.status}" placeholder="Status">
                                        <div class="invalid-feedback">{{ error.status }}</div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">Email</label>
                                        <input type="email" class="form-control" v-model="email" :class="{ 'is-invalid': error.email}" placeholder="Email">
                                        <div class="invalid-feedback">{{ error.email }}</div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">Mobile</label>
                                        <input type="text" class="form-control" v-model="mobile" :class="{ 'is-invalid': error.mobile}" placeholder="Mobile">
                                        <div class="invalid-feedback">{{ error.mobile }}</div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">Linkedin</label>
                                        <input type="text" class="form-control" v-model="linkedin" :class="{ 'is-invalid': error.linkedin}" placeholder="Linkedin">
                                        <div class="invalid-feedback">{{ error.linkedin }}</div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">Github</label>
                                        <input type="text" class="form-control" v-model="github" :class="{ 'is-invalid': error.github}" placeholder="Github">
                                        <div class="invalid-feedback">{{ error.github }}</div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">Facebook</label>
                                        <input type="text" class="form-control" v-model="facebook" :class="{ 'is-invalid': error.facebook}" placeholder="Facebook">
                                        <div class="invalid-feedback">{{ error.facebook }}</div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">Dribbble</label>
                                        <input type="text" class="form-control" v-model="dribbble" :class="{ 'is-invalid': error.dribbble}" placeholder="dribbble">
                                        <div class="invalid-feedback">{{ error.dribbble }}</div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">Pinterest</label>
                                        <input type="text" class="form-control" v-model="pinterest" :class="{ 'is-invalid': error.pinterest}" placeholder="Pinterest">
                                        <div class="invalid-feedback">{{ error.pinterest }}</div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">Twitter</label>
                                        <input type="text" class="form-control" v-model="twitter" :class="{ 'is-invalid': error.twitter}" placeholder="Twitter">
                                        <div class="invalid-feedback">{{ error.twitter }}</div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">Behance</label>
                                        <input type="text" class="form-control" v-model="behance" :class="{ 'is-invalid': error.behance}" placeholder="Behance">
                                        <div class="invalid-feedback">{{ error.behance }}</div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="mb-3">
                                        <label class="form-label">Content</label>
                                        <editor
                                           api-key="vurtoro60e1p8issqadzfw43v19naju6d98ggqr1tnggr8h9"
                                            :init="{
                                                menubar: false,
                                                plugins: 'lists link image emoticons',
                                                toolbar: 'styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link image emoticons'
                                            }"
                                            v-model="content"
                                            :class="{ 'is-invalid': error.content}"
                                        />
                                        <div class="invalid-feedback">{{ error.content }}</div>
                                    </div>
                                </div>

                                <div class="mt-4">
                                    <div class="d-flex">
                                        <button type="submit" class="btn btn-primary ms-auto" @click="submit">
                                            <span>Create</span> 
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageTitle from '../../components/PageTitle';
import { authCheck } from '@/helpers/auth/auth-check';
import Editor from '@tinymce/tinymce-vue'
import { ref, onMounted, inject } from 'vue';
import { useRouter } from "vue-router";
import { store } from '../../store.js';
import axios from 'axios';

export default {
    name: 'CreateBlog',
    components: {
        PageTitle,
        'editor': Editor
    },
    setup() {
        const router = useRouter();
        const name = ref('');
        const error = ref([]);
        const nationality = ref('');
        const aboutImg = ref('');
        const aboutLgImg = ref('');
        const profile = ref('');
        const resume = ref('');
        const age = ref('');
        const status = ref('');
        const email = ref('');
        const linkedin = ref('');
        const github = ref('');
        const facebook = ref('');
        const dribbble = ref('');
        const pinterest = ref('');
        const twitter = ref('');
        const behance = ref('');
        const content = ref('');
        const language = ref('');
        const address = ref('');
        const mobile = ref('');
        const swal = inject('$swal');

        onMounted(() => {
            authCheck();
        });
        return { store, swal, router, error, name, nationality, aboutImg, aboutLgImg,
                profile, resume, age, status, email, mobile, linkedin, github, facebook, 
                address, language, dribbble, pinterest, twitter, behance, content }
    },
    methods: {
        aboutImgUpload(e) {
            var files = e.target.files || e.dataTransfer.files;
            if(!files.length)
            {
                this.error.form = true;
                this.error.aboutImg = 'Image required';
            } else {
                this.error.form = false;
                this.error.image = '';
                this.aboutImg = files[0];
            }
        },
        aboutLgImgUpload(e) {
            var files = e.target.files || e.dataTransfer.files;
            if(!files.length)
            {
                this.error.form = true;
                this.error.aboutLgImg = 'Image required';
            } else {
                this.error.form = false;
                this.error.image = '';
                this.aboutLgImg = files[0];
            }
        },
        profileUpload(e) {
            var files = e.target.files || e.dataTransfer.files;
            if(!files.length)
            {
                this.error.form = true;
                this.error.profile = 'Image required';
            } else {
                this.error.form = false;
                this.error.image = '';
                this.profile = files[0];
            }
        },
        resumeUpload(e) {
            var files = e.target.files || e.dataTransfer.files;
            if(!files.length)
            {
                this.error.form = true;
                this.error.resume = 'File is required';
            } else {
                this.error.form = false;
                this.error.image = '';
                this.resume = files[0];
            }
        },
        submit () {
            this.error.form = false;
            this.clear();

            if (!this.name) {
                this.error.form = true;
                this.error.name = 'Name is required';
            }

            if (!this.nationality) {
                this.error.form = true;
                this.error.nationality = 'Nationality is required';
            }

            if (!this.age) {
                this.error.form = true;
                this.error.age = 'Age is required';
            }

            if (!this.status) {
                this.error.form = true;
                this.error.status = 'Status is required';
            }

            if (!this.email) {
                this.error.form = true;
                this.error.email = 'Email is required';
            }

            if (!this.mobile) {
                this.error.form = true;
                this.error.mobile = 'Mobile is required';
            }

            if (!this.github) {
                this.error.form = true;
                this.error.github = 'Github is required';
            }

            if (!this.aboutImg) {
                this.error.form = true;
                this.error.aboutImg = 'Image is required';
            }

            if (!this.aboutLgImg) {
                this.error.form = true;
                this.error.aboutLgImg = 'Image is required';
            }

            if (!this.profile) {
                this.error.form = true;
                this.error.profile = 'Image is required';
            }

            if (!this.resume) {
                this.error.form = true;
                this.error.resume = 'File is required';
            }

            if (!this.content) {
                this.error.form = true;
                this.error.content = 'Content is required';
            }

            if (!this.error.form) {
                this.goSubmit();
            }
        },
        async goSubmit() {
            store.loading = true;
            let model = this;
            await axios
            .post(process.env.VUE_APP_ENDPOINT+'/api/info',{
                name: this.name,
                about_content: this.content,
                age: this.age,
                phone: this.mobile,
                nationality: this.nationality,
                language: this.language,
                email: this.email,
                address: this.address,
                freelance_status: this.status,
                facebook: this.facebook,
                twitter: this.twitter,
                pinterest: this.pinterest,
                behance: this.behance,
                linkedin: this.linkedin,
                dribbble: this.dribbble,
                github: this.github,
                brand_image: this.profile,
                about_image: this.aboutImg,
                about_image_lg: this.aboutLgImg,
                cvfile: this.resume
            },{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(function (res) {
                store.loading = false;
                model.swal({
                    icon: 'success',
                    text: res.data.message,
                    showCloseButton: true,
                    showConfirmButton: false
                });
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    model.router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 422) {
                    model.swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    model.swal({
                        icon: 'error',
                        text: model.app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        },
        clear() {
            this.error = [];
        }
    },
    watch: {
        name () {
            if (!this.name) {
                this.error.form = true;
                this.error.name = 'Name is required';
            } else {
                this.error.form = false;
                this.error.name = '';
            }
        },
        nationality () {
            if (!this.nationality) {
                this.error.form = true;
                this.error.nationality = 'Nationality is required';
            } else {
                this.error.form = false;
                this.error.nationality = '';
            }
        },
        age () {
            if (!this.age) {
                this.error.form = true;
                this.error.age = 'Age is required';
            } else {
                this.error.form = false;
                this.error.age = '';
            }
        },
        status () {
            if (!this.status) {
                this.error.form = true;
                this.error.status = 'Status is required';
            } else {
                this.error.form = false;
                this.error.status = '';
            }
        },
        email () {
            if (!this.email) {
                this.error.form = true;
                this.error.email = 'Email is required';
            } else {
                this.error.form = false;
                this.error.email = '';
            }
        },
        mobile () {
            if (!this.mobile) {
                this.error.form = true;
                this.error.mobile = 'Mobile is required';
            } else {
                this.error.form = false;
                this.error.mobile = '';
            }
        },
        linkedin () {
            if (!this.linkedin) {
                this.error.form = true;
                this.error.linkedin = 'Linkedin is required';
            } else {
                this.error.form = false;
                this.error.linkedin = '';
            }
        },
        github () {
            if (!this.github) {
                this.error.form = true;
                this.error.github = 'Github is required';
            } else {
                this.error.form = false;
                this.error.github = '';
            }
        },
        content () {
            if (!this.content) {
                this.error.form = true;
                this.error.content = 'Content required';
            } else {
                this.error.form = false;
                this.error.content = '';
            }
        },
    }
}
</script>

<style scoped>
    .hide{
        display: none;
    }
</style>