<template>
    <div>
        <PageTitle title="Personal Info" :breadcrumb="[{ name: 'Personal Info' }]">
        </PageTitle>

        <div class="page-body">
            <div class="container-xl">
                <div class="col-12">
                    <div class="card">
                        <!-- <div class="table-responsive"> -->
                        <div>
                        <table class="table table-vcenter table-mobile-md card-table">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Name</th>
                                    <th>Address</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(row, index) in info" :key="index">
                                    <td><router-link :to="{name: 'InfoDetails', params: { id: row.id } }"> {{ row.id }} </router-link></td>
                                    <td> {{ row.name }} </td>
                                    <td> {{ row.address }} </td>
                                    <td> {{ row.email }}</td>
                                    <td> {{ row.phone }} </td>
                                    <td class="text-end">
                                        <span class="dropdown">
                                            <button class="btn dropdown-toggle align-text-top" data-bs-boundary="viewport" data-bs-toggle="dropdown">Actions</button>
                                            <div class="dropdown-menu dropdown-menu-end">
                                                <router-link :to="{ name: 'EditInfo', params: { id: row.id } }" class="dropdown-item">Edit</router-link>
                                                <a class="dropdown-item" @click="deleteInfo(row.id)">Delete</a>
                                            </div>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageTitle from '../../components/PageTitle';
import { authCheck } from '@/helpers/auth/auth-check';
import { ref, onMounted, inject } from 'vue';
import { useRouter, useRoute } from "vue-router";
import axios from 'axios';
import { store } from '../../store.js';

export default {
    name: 'CreateBlog',
    components: {
        PageTitle
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        let info = ref();
        const swal = inject('$swal');

        async function getInfo() {
            store.loading = true;
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/api/info')
            .then(function (res) {
                store.loading = false;
                info.value = res.data.data;
            })
            .catch(function (error) {
                if (error.response.status == 401) {
                    router.push({ name: 'Logout' });
                }
            });
        }
        onMounted(() => {
            authCheck();
            getInfo();
        });
        return { info, route, router, swal };
    },
    methods: {
        deleteInfo(infoId){
            this.swal({
                title: "Delete this Blog?",
                text: "Are you sure? You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#b81307",
                confirmButtonText: "Yes, Delete it!"
            }).then((result) => { // <--
                if (result.value) { // <-- if confirmed
                    store.loading = true;

                    axios
                    .delete(process.env.VUE_APP_ENDPOINT+'/api/info/'+infoId)
                    .then(function () {
                        store.loading = false;
                        window.location.reload();
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            this.router.push({ name: 'Logout' });
                        }
                    });
                }
            });
            
        }
    },
}
</script>