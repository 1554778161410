<template>
    <div class="row">
        <div class="page-body">
            <div class="container-xl">
                <div class="mt-3">
                    <div class="float-start">
                        <h2 class="page-title">
                            {{ title }}
                        </h2>
                        <div class="d-flex align-items-center" v-if="breadcrumb">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                                    <li class="breadcrumb-item text-capitalize" v-for="(row, index) in breadcrumb" :key="index">
                                        <router-link :to="row.link" v-if="row.link">{{ row.name }}</router-link>
                                        <span v-else>{{ row.name }}</span>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="float-end">
                        <slot></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
	props: ['title','breadcrumb']
}
</script>