<template>
  <div class="row">
    
	</div><!--row -->
</template>

<script>
import { onMounted } from 'vue';
import axios from 'axios';
import { store } from '../../store.js';

export default {
    setup() {

        async function logout() {
            store.loading = true;
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/api/logout')
            .then(function () {
                store.loading = false;
                localStorage.removeItem('authToken');
                window.location.href = '/login';
                return false;
            })
            .catch(function () {
                store.loading = false;
                localStorage.removeItem('authToken');
                window.location.href = '/login';
            });
        }

        onMounted(() => {
          logout();
        });

        return {  };
    }
}
</script>