import { createApp } from 'vue'
import App from './App.vue'
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.min.js";
// import "bootstrap";
// import '@tabler/core';
import router from './router';
import './assets/tabler/tabler.min.css';
import './assets/global.css';
import Popper from 'vue3-popper';
import VueSweetAlert from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

let app = createApp(App)
app.use(router)
    .use(VueSweetAlert)
    .component("Popper", Popper)
    .mount('#app');

app.config.globalProperties.$error500 = 'Something went wrong'
