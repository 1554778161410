<template>
    <div class="welcome-div">
        <div data-heading="WELCOME">WELCOME</div>
    </div>
</template>

<style>
.welcome-div {
  height: 100vh;
  background: linear-gradient(
    to right,
    white 0%,
    transparent 50%,
    black 0%,
    #1d273b 50%
  );
}

.welcome-div div {
  color: white;
  white-space: nowrap;
  font-size: 5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.welcome-div div::before {
  content: attr(data-heading);
  position: absolute;
  left: 0;
  color: black;
  width: 50%;
  overflow: hidden;
}
</style>