import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import CreateBlog from '../views/CreateBlog.vue';
import BlogPage from '../views/BlogPage.vue';
import BlogDetails from '../views/BlogDetails.vue';
import EditBlog from '../views/EditBlog.vue';
import GetInfo from '../views/Information/GetInfo.vue';
import InfoDetails from '../views/Information/InfoDetails.vue';
import CreateInfo from '../views/Information/CreateInfo.vue';
import EditInfo from '../views/Information/EditInfo.vue';
import Login from '../views/Account/LoginPage.vue';
import Logout from '../views/Account/LogoutPage.vue';
import EmailList from '../views/Email/EmailList.vue';
import EmailDetails from '../views/Email/Details.vue';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'Home',
            component: HomePage,
            meta: {
                title: 'Home Page'
            }
        },

        // email
        {
            path: '/admin/emails',
            name: 'EmailList',
            component: EmailList,
            meta: {
                title: 'Email'
            }
        },
        {
            path: '/admin/emails/:id',
            name: 'EmailDetails',
            component: EmailDetails,
            meta: {
                title: 'Email Details'
            }
        },

        // blog
        {
            path: '/admin/blog',
            name: 'BlogPage',
            component: BlogPage,
            meta: {
                title: 'Blog Page'
            }
        },
        {
            path: '/admin/blog/:id',
            name: 'BlogDetails',
            component: BlogDetails,
            meta: {
                title: 'BlogDetails'
            }
        },
        {
            path: '/admin/blog/edit/:id',
            name: 'EditBlog',
            component: EditBlog,
            meta: {
                title: 'EditBlog'
            }
        },
        {
            path: '/admin/blog/create',
            name: 'CreateBlog',
            component: CreateBlog,
            meta: {
                title: 'Create Blog'
            }
        },

        // information 
        {
            path: '/admin/info',
            name: 'Information',
            component: GetInfo,
            meta: {
                title: 'Personal Info'
            }
        },
        {
            path: '/admin/info/:id',
            name: 'InfoDetails',
            component: InfoDetails,
            meta: {
                title: 'Personal Info Details'
            }
        },
        {
            path: '/admin/info/edit/:id',
            name: 'EditInfo',
            component: EditInfo,
            meta: {
                title: 'EditInfo'
            }
        },
        {
            path: '/admin/info/create',
            name: 'CreateInfo',
            component: CreateInfo,
            meta: {
                title: 'Create Info'
            }
        },

        // Authentication
        {
            path: '/logout',
            name: 'Logout',
            component: Logout,
            meta: {
                title: 'Logout'
            }
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
            meta: {
                title: 'Login'
            }
        }
    ]
});

router.beforeEach((to) => {
    if(to.meta.title) {
        document.title = to.meta.title + ' - Linn';
    } else {
        document.title = 'Linn';
    }
});

export default router