<template>
    <div>
        <PageTitle title="Create Blog" :breadcrumb="[{ name: 'Blog', link: '/admin/blog' }, { name: 'Edit' } ]">
        </PageTitle>
        <div class="page-body">
            <div class="container-xl">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-6 col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Title</label>
                                        <input type="text" class="form-control" v-model="title" placeholder="Title">
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="mb-3">
                                        <label class="form-label">Image</label>
                                        <!-- <button @click="importBtn" class="btn btn-outline-primary" ><i class="ti ti-cloud-upload"></i> <span>Upload Photo</span></button> -->
                                        <input class="form-control inputFile" id="inputFile" name="inputFile" type="file" accept=".jpg,.jepg,.png" v-on:change="onFileChange">
                                    </div>
                                </div>
                                <div class="col-sm-2 col-md-2">
                                    <div>
                                        <img :src="featuredImage" class="img-thumbnail" width="100">
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="mb-3">
                                        <label class="form-label">Content</label>
                                        <editor
                                           api-key="vurtoro60e1p8issqadzfw43v19naju6d98ggqr1tnggr8h9"
                                            :init="{
                                                menubar: false,
                                                plugins: 'lists link image emoticons',
                                                toolbar: 'styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link image emoticons'
                                            }"
                                            v-model="content"
                                            :class="{ 'is-invalid': error.content}"
                                        />
                                        <div class="invalid-feedback">{{ error.content }}</div>
                                    </div>
                                </div>

                                <div class="mt-4">
                                    <div class="d-flex">
                                        <button type="submit" class="btn btn-primary ms-auto" @click="update">
                                            <span>Update</span> 
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageTitle from '../components/PageTitle';
import { authCheck } from '@/helpers/auth/auth-check';
import Editor from '@tinymce/tinymce-vue'
import { ref, onMounted, inject } from 'vue';
import { useRouter, useRoute } from "vue-router";
import { store } from '../store.js';
import axios from 'axios';

export default {
    name: 'CreateBlog',
    components: {
        PageTitle,
        'editor': Editor
    },
    setup() {
        const router = useRouter();
        const title = ref('');
        const error = ref([]);
        const image = ref('');
        const featuredImage = ref('');
        const content = ref('');
        const swal = inject('$swal');
        let blog = ref([]);
        const route = useRoute();

        async function getBlogDetails() {
            store.loading = true;
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/api/blog/'+route.params.id+'/edit')
            .then(function (res) {
                store.loading = false;
                blog.value = res.data.blog;
                title.value = res.data.blog.title;
                featuredImage.value = res.data.blog.featured_image;
                content.value = res.data.blog.details;
            })
            .catch(function (error) {
                if (error.response.status == 401) {
                    router.push({ name: 'Logout' });
                }
            });
        }

        onMounted(() => {
            authCheck();
            getBlogDetails();
        });
        return { store, swal, router, route,  error, title, content, image, blog, featuredImage }
    },
    methods: {
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            this.image = files[0];
        },
        async update() {
            store.loading = true;
            let model = this;
            
            await axios
            .post(process.env.VUE_APP_ENDPOINT+'/api/blog/'+model.route.params.id,{
                title: this.title,
                featured_image: this.image,
                details: this.content,
                _method: 'put'
            },{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(function (res) {
                store.loading = false;
                model.swal({
                    icon: 'success',
                    text: res.data.message,
                    showCloseButton: true,
                    showConfirmButton: false
                }).then(function(){
                    window.location.reload();
                });
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    model.router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 422) {
                    model.swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    model.swal({
                        icon: 'error',
                        text: model.app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        }
    }
}
</script>

<style scoped>
    .hide{
        display: none;
    }
</style>