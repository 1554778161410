import { useRouter } from 'vue-router';

function authCheck()
{
    const router = useRouter();
    if(!localStorage.getItem('authToken'))
    {
        router.push({ name: 'Logout' });
        return false;
    }
}

export { authCheck };
