<template>
    <div>
        <PageTitle title="Email Details" :breadcrumb="[{ name: 'Email', link: '/admin/emails' }, { name: 'Email Details' }]">
        </PageTitle>
        <div class="page-body">
            <div class="container-xl">
                <div class="card card-lg">
                    <div class="card-body">
                        <div class="space-y-4">
                            <div>
                                <div class="header mb-3">
                                    <h1>{{ email.name }} ({{ email.email }})</h1>
                                    <h3>Subject: {{ email.subject }}</h3>
                                    <h4 class="text-muted">Date: {{ email.created_at }}</h4>
                                </div>
                                
                                <div v-html="email.message"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageTitle from '../../components/PageTitle';
import { authCheck } from '@/helpers/auth/auth-check';
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from "vue-router";
import axios from 'axios';
import { store } from '../../store.js';

export default {
    name: 'CreateBlog',
    components: {
        PageTitle
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        let email = ref([]);

        async function getEmailDetails() {
            store.loading = true;
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/api/email/'+route.params.id)
            .then(function (res) {
                store.loading = false;
                email.value = res.data.email;
            })
            .catch(function (error) {
                if (error.response.status == 401) {
                    router.push({ name: 'Logout' });
                }
            });
        }
        onMounted(() => {
            authCheck();
            getEmailDetails();
        });
        return { email };
    }
}
</script>